const state = {
    items: [],
    isLoadNextPage: true,
    loadItems: true,
    page: 1,
    moderateCount: 0,
};
// getters
const getters = {};

const mutations = {
    setItems(state, data) {
        if (typeof data === 'object') {
            if (state.page > 1) {
                for (const i in data) {
                    state.items.push(data[i]);
                }
            } else {
                state.items = data;
            }
        } else {
            state.items = [];
        }
        state.page += 1;
    },
    deleteItem(state, data) {
        for (const s in state.items) {
            if (state.items[s].id === data) {
                state.items.splice(s, 1);
            }
        }
    },
    setNewPage(state) {
        state.items = [];
        state.page = 1;
        state.isLoadNextPage = true;
        state.loadItems = true;
    },
    setLoadItems(state, data) {
        state.loadItems = data;
    },
    setLoadNextPage(state, data) {
        state.isLoadNextPage = data;
    },
    setModerateCount(state, data) {
        state.moderateCount = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
