const state = {
    items: {
        categoryModal: {
            name: 'categoryModal',
            show: false,
        },
        deleteImpossibleModal: {
            name: 'deleteImpossibleModal',
            show: false,
        },
        teamCategoryModal: {
            name: 'teamCategoryModal',
            show: false,
        },
        teamDeleteImpossibleModal: {
            name: 'teamDeleteImpossibleModal',
            show: false,
        },
        teamUserAddNotify: {
            name: 'teamUserAddNotify',
            show: false,
        },
        articleErrorModal: {
            name: 'articleErrorModal',
            show: false,
        },
        addVideoModal: {
            name: 'addVideoModal',
            show: false,
        },
        addLinkModal: {
            name: 'addLinkModal',
            show: false,
        },
        schemeErrorModal: {
            name: 'schemeErrorModal',
            show: false,
        },
        fileErrorModal: {
            name: 'fileErrorModal',
            show: false,
        },
        addLink: {
            name: 'addLink',
            show: false,
        },
        addVideo: {
            name: 'addVideo',
            show: false,
        },
        errorSchemeUpload: {
            name: 'errorSchemeUpload',
            show: false,
        },
        errorScreenUpload: {
            name: 'errorScreenUpload',
            show: false,
        },
        isNotifyReset: {
            name: 'isNotifyReset',
            show: false,
        },
        somethingWentWrong: {
            name: 'somethingWentWrong',
            show: false,
        },
    },
    notify: {
        articleMoved: 'Статья успешно перемещена',
        articleDeleted: 'Статья отправлена в архив',
        articlePublic: 'Статья успешно опубликована',
        articleUnPublic: 'Статья снята с публикации',
        ruleEmail: 'Email-уведомления успешно отправлены',
        ruleLink: 'Ссылка скопирована в буфер обмена',
        ruleWriterSet: 'Писатель успешно назначен',
        ruleWriterChange: 'Писатель успешно изменён',
        teamUserAdd: 'Аккаунт успешно создан',
        teamUserVerify: 'Кандидат отправлен на проверку',
        teamUserReject: 'Кандидат успешно отклонён',
        teamUserReturn: 'Кандидат успешно возвращён на этап загрузки документов',
        teamUserActivate: 'Кандидат успешно превратился в сотрудника',
        contractLink: 'Ссылка для ознакомления успешно скопирована',
        contractMoved: 'Договор успешно перемещён',
        contractDeleted: 'Договор успешно удален',
        sendSmsRemind: 'СМС-напоминание успешно отправлено',
        contactAdd: 'Контакт успешно создан',
        contactBlock: 'Контакт успешно заблокирован',
    },
};

// getters
const getters = {

};

const actions = {
    open(context, data) {
        if (data.name && state.items[data.name]) {
            setTimeout(() => {
                context.commit('open', { name: data.name });
            }, 10);
        }
    },
    showNotify(context, data) {
        if (data.name && state.items[data.name]) {
            context.commit('showNotify', { name: data.name });
            setTimeout(() => {
                context.commit('close', { name: data.name });
            }, 3000);
        }
    },
};

const mutations = {
    close(state, data) {
        if (data.name && state.items[data.name]) {
            state.items[data.name].show = false;
            if (data.name === 'addRule') {
                if (document.querySelector('.rules')) {
                    document.querySelector('.rules').style.opacity = '';
                }
            }
            document.getElementsByTagName('body')[0].classList.remove('noscroll');
        }
    },
    open(state, data) {
        if (data.name && state.items[data.name]) {
            state.items[data.name].show = true;
        }
    },
    showNotify(state, data) {
        if (data.name && state.items[data.name]) {
            state.items[data.name].show = true;
            setTimeout(() => {
                state.items[data.name].show = false;
            }, 3000);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
