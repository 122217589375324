import { createApp } from 'vue';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import ToasterCustom from './helpers/vue-toaster';
import App from './App.vue';
import router from './router';
import store from './store';
import VueInputMask from './helpers/vue-inputmask';
import './assets/sass/style.sass';

// Vue.config.productionTip = false
const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueInputMask)
    .use(ToasterCustom, {
        // One of the options
        position: 'bottom-left',
        duration: 3000,
    });

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // integrations: [new BrowserTracing()],
    // integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
});

window.app = app.mount('#app');
axios.interceptors.response.use((response) => {
    if (response.data.version) {
        if (response.data.version !== process.env.VUE_APP_API_VERSION && response.data.version !== store.state.notifyPageVersion) {
            store.dispatch('popups/open', { name: 'isNotifyReset' });
            store.commit('setNotifyPageVersion', response.data.version);
        }
    }
    return response;
}, (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
